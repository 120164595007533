import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"

const Author = ({ name }) => {
  const staffCards = useStaticQuery(
    graphql`
      query {
        allMdx(
          filter: { fileAbsolutePath: { regex: "/content/staff/" } }
          sort: { fields: frontmatter___order, order: ASC }
        ) {
          edges {
            node {
              id
              frontmatter {
                name
                image {
                  childImageSharp {
                    fluid(maxWidth: 400) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                jobTitle
              }
              body
            }
          }
        }
      }
    `
  )

  let staff = staffCards.allMdx.edges

  let authorNames = name instanceof Array ? name : [name];

  let authorInfo = authorNames.map((author) => {
    let authorObj = staff.filter(name => {
        return name.node.frontmatter.name === author
      })
    return authorObj[0].node.frontmatter;
  })
  
  return (
    <section className="authors">
      { authorInfo.map((author) => 
        <div className="author">
          <Image
            className="author__image"
            src={author.image.map(image => image.childImageSharp.fluid.src) }
            alt={author.name}
          />
          <div className="author__info">
            <h2 className="author__name">{author.name}</h2>
            <p className="author__title">{author.jobTitle}</p>
          </div>
        </div>
      )}
      
    </section>
  )
}

Author.defaultProps = {
  name: "Lunar Team",
}

Author.propTypes = {
  name: PropTypes.string.isRequired,
}

export default Author

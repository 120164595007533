import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

const TopicLinks = ({ topics }) => (
  <ul className="topic-list">
    {topics.sort().map((topic, i) => (
      <li className="topic-list__item" key={i}>
        <Link to="/blog" state={{ checked: `${topic}` }}>
          {topic}
        </Link>
      </li>
    ))}
  </ul>
)

TopicLinks.propTypes = {
  topics: PropTypes.arrayOf(PropTypes.string),
}

export default TopicLinks

import PropTypes from "prop-types"
import React from "react"
import TextBlock from "./TextBlock.js"
import Img from "gatsby-image"

export default function ArticleHero({ hero }) {
  let { title, author, excerpt, featuredImage } = hero

  const content = {
    title: title,
    subtitle: author,
    text: excerpt,
  }

  return (
    <section className="single-blog-hero">
      {featuredImage.extension !== "svg" ? (
        <Img
          className={"single-blog-hero__background-image"}
          fluid={featuredImage.childImageSharp.fluid}
        />
      ) : (
        <figure className="single-blog-hero__background-image">
          <img alt={title} src={featuredImage.publicURL} />
        </figure>
      )}
      <div className="single-blog-hero__text-content">
        <TextBlock {...content} />
      </div>
    </section>
  )
}

ArticleHero.propTypes = {
  hero: PropTypes.object.isRequired,
}

import React from "react"
import SharePost from "./SharePost"
import TopicLinks from "./TopicLinks"
import Author from "../components/AuthorInfo"

export default function BlogFooter({ topics, title, name }) {
  return (
    <section className="blog-post__footer">
      <div className="blog-post__footer--details">
        <Author name={name} />
        <SharePost title={title} />
      </div>
      <TopicLinks topics={topics} />
    </section>
  )
}

import React from "react"
import PropTypes from "prop-types"
import twitter from "../assets/images/twitter.svg"

const SharePost = () => {
  const twitterLink = encodeURI(
    `https://twitter.com/intent/tweet?text=${
      typeof window !== "undefined" ? window.location.href : ""
    }`
  )

  return (
    <section className="share-post">
      <img className="share-post__icon" src={twitter} alt="" />

      <p>
        {"If this article was helpful, "}
        <a id="twitterLink" href={twitterLink}>
          tweet it
        </a>
        .
      </p>
    </section>
  )
}

SharePost.propTypes = {
  title: PropTypes.string.isRequired,
}

export default SharePost

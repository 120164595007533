import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"

import SEO from "../components/SEO"
import ArticleHero from "../components/HeroArticle"
import ContactForm from "../components/ContactForm"
import BlogFooter from "../components/BlogFooter"
import { MDXRenderer } from "gatsby-plugin-mdx"

export default function Post({
  data, // this prop will be injected by the GraphQL query
}) {
  const post = data.mdx // data.mdx holds your post data
  const {title, excerpt, featuredImage, author, topics} = post.frontmatter
  const authorsString = author instanceof Array ? author.join(' and ') : author
  const hero = {
    title: title,
    author: `Written by ${ authorsString }`,
    excerpt: excerpt,
    featuredImage: featuredImage,
  }
  return (
    <>
      <SEO title={title} description={excerpt} />
      <ArticleHero hero={hero} />
      <div className="blog-post-container container">
        <Helmet title={`Lunar Blog - ${title}`} />
        <div className="blog-post">
          <div className="blog-post-content">
            <MDXRenderer>{post.body}</MDXRenderer>
          </div>
          <BlogFooter
            topics={topics}
            title={title}
            name={author}
          />
        </div>
      </div>
      <ContactForm />
    </>
  )
}

export const pageQuery = graphql`
  query BlogPostByPath($path: String!) {
    mdx(frontmatter: { path: { eq: $path } }) {
      body
      frontmatter {
        date(formatString: "MMMM DD, YYYY HH:MM:SS")
        path
        title
        author
        topics
        excerpt
        featuredImage {
          extension
          publicURL
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
